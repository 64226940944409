import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SiteInfo, SiteInfoAuth, SiteInfoBranding, SiteInfoGeneral } from '../../models/site-info.model';

@Injectable({
  providedIn: 'root'
})
export class SiteInfoService {
  constructor(private http: HttpClient) {}
  
  async getSiteInfo(): Promise<SiteInfo> {
    const r = await this.http.get(`/api/site-info`).toPromise()
    return new SiteInfo(r)
  }

  async getSiteInfoGeneral(): Promise<SiteInfoGeneral> {
    const r = await this.http.get(`/api/site-info/general`).toPromise()
    return new SiteInfoGeneral(r)
  }

  async getSiteInfoAuth(): Promise<SiteInfoAuth> {
    const r = await this.http.get(`/api/site-info/auth`).toPromise()
    return new SiteInfoAuth(r)
  }

  async getSiteInfoBranding(): Promise<SiteInfoBranding> {
    const r = await this.http.get(`/api/site-info/branding`).toPromise()
    return new SiteInfoBranding(r)
  }
}
